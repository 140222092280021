export default function() {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        theme: 'primary',
        class: 'top-gap',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'description',
            },
            data: {
              content: get('Residential.item.description'),
            },
          },
        ],
      },
    },

    ...(get('Residential.item.customFields.section-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          captionPlacement: i['image-caption-placement'] || 'inside ',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('Residential.item.customFields.icons-1') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'full',
        class: 'features',
        theme: get('Residential.item.customFields.icons-settings-1.theme') || 'none',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Residential.item.customFields.icons-settings-1.columns')) || 4,
              iconBackgroundColor: get(
                'Residential.item.customFields.icons-settings-1.show-icon-background-color',
              )
                ? get('Residential.item.ustomFields.icons-settings-1.icon-background-color')
                : 'inherit',

              borderTheme: get('Residential.item.customFields.icons-settings-1.border-theme'),

              padding: '0',

              placement: get('Residential.item.customFields.icons-settings-1.placement'),
            },
            data: {
              content: get('Residential.item.customFields.icons-1'),
            },
            variant: get('Residential.item.customFields.icons-settings-1.variant') || 'none',
          },
        ],
      },
    },
    ...(get('Residential.item.customFields.section-2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          captionPlacement: i['image-caption-placement'] || 'inside ',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('Residential.item.customFields.icons-2') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'full',
        class: 'features',
        theme: get('Residential.item.customFields.icons-settings-2.theme') || 'none',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Residential.item.customFields.icons-settings-2.columns')) || 4,
              iconBackgroundColor: get(
                'Residential.item.customFields.icons-settings-2.show-icon-background-color',
              )
                ? get('Residential.item.ustomFields.icons-settings-2.icon-background-color')
                : 'inherit',

              borderTheme: get('Residential.item.customFields.icons-settings-2.border-theme'),
              placement: get('Residential.item.customFields.icons-settings-2.placement'),
              padding: '0',
            },
            data: {
              content: get('Residential.item.customFields.icons-2'),
            },
            variant: get('Residential.item.customFields.icons-settings-2.variant') || 'none',
          },
        ],
      },
    },
    ...(get('Residential.item.customFields.section-3') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          captionPlacement: i['image-caption-placement'] || 'inside ',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('Residential.item.customFields.icons-3') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'full',
        class: 'features',
        theme: get('Residential.item.customFields.icons-settings-3.theme') || 'none',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Residential.item.customFields.icons-settings-3.columns')) || 4,
              iconBackgroundColor: get(
                'Residential.item.customFields.icons-settings-3.show-icon-background-color',
              )
                ? get('Residential.item.ustomFields.icons-settings-3.icon-background-color')
                : 'inherit',

              borderTheme: get('Residential.item.customFields.icons-settings-3.border-theme'),
              placement: get('Residential.item.customFields.icons-settings-3.placement'),
              padding: '0',
            },
            data: {
              content: get('Residential.item.customFields.icons-3'),
            },
            variant: get('Residential.item.customFields.icons-settings-3.variant') || 'none',
          },
        ],
      },
    },
    ...(get('Residential.item.customFields.section-4') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('Residential.item.customFields.icons-4') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'full',
        class: 'features',
        theme: get('Residential.item.customFields.icons-settings-4.theme') || 'none',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Residential.item.customFields.icons-settings-4.columns')) || 4,
              iconBackgroundColor: get(
                'Residential.item.customFields.icons-settings-4.show-icon-background-color',
              )
                ? get('Residential.item.ustomFields.icons-settings-4.icon-background-color')
                : 'inherit',

              borderTheme: get('Residential.item.customFields.icons-settings-4.border-theme'),
              placement: get('Residential.item.customFields.icons-settings-4.placement'),
              padding: '0',
            },
            data: {
              content: get('Residential.item.customFields.icons-4'),
            },
            variant: get('Residential.item.customFields.icons-settings-4.variant') || 'none',
          },
        ],
      },
    },
    ...(get('Residential.item.customFields.section-5') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          captionPlacement: i['image-caption-placement'] || 'inside ',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('Residential.item.customFields.icons-5') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'full',
        class: 'features',
        theme: get('Residential.item.customFields.icons-settings-5.theme') || 'none',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Residential.item.customFields.icons-settings-5.columns')) || 4,
              iconBackgroundColor: get(
                'Residential.item.customFields.icons-settings-5.show-icon-background-color',
              )
                ? get('Residential.item.ustomFields.icons-settings-5.icon-background-color')
                : 'inherit',

              borderTheme: get('Residential.item.customFields.icons-settings-5.border-theme'),
              placement: get('Residential.item.customFields.icons-settings-5.placement'),
              padding: '0',
            },
            data: {
              content: get('Residential.item.customFields.icons-5'),
            },
            variant: get('Residential.item.customFields.icons-settings-5.variant') || 'none',
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.media.gallery'),
      props: {
        theme: 'primary',
        style: 'padding-block: 3.5%; 14%; --section-padding-inline: 0',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            props: {
              class: 'residential-gallery',
            },
            data: {
              images: get('Residential.item.media.gallery'),
            },
          },
        ],
      },
    },

    {
      component: 'FlatfinderGoBack',
      data: {
        image: get('Residential.item.customFields.go-back.image'),
        goBackUrl: get('Residential.item.customFields.go-back.url'),
        goBackTitle: get('Residential.item.customFields.go-back.title'),
      },
    },
  ]
}
