<template>
  <Loader theme="overlay" :value="promises.fetch" class="residential-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/residential.js'

import FlatfinderGoBack from '@/components/FlatfinderGoBack'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val)
          .then(data => {
            if (!data) return this.$router.push({ name: 'notfound' })
          })
          .then(() => this.$nextTick(() => this.initAnimation()))
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Residential', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { FlatfinderGoBack }
    },
  },
  methods: {
    ...mapActions('Residential', ['fetch']),
    async initAnimation() {
      inView('.hero', () => {
        animate(
          '.hero',
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: stagger(0.2) },
          { easing: 'linear' },
        )
      })
      inView('.hero__content', () => {
        animate('.hero__content', { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.4 })
      })

      const images = document.querySelectorAll('.kpb-gallery__item')
      if (images.length) {
        animate(
          images,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: stagger(0.2) },
          { easing: 'linear' },
        )
      }

      const button = document.querySelectorAll('a[data-cta]')
      if (button.length) {
        animate(button, { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.6 })
      }
    },
  },

  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.residential-page {
  [data-kpb-caption-placement='outside'] .kpb-gallery__item-caption-wrapper {
    padding: 0;
  }

  .kpb-images-text-section__images {
    a {
      color: white !important;
      font-style: italic;
    }
  }

  .top-gap {
    padding: calc(1.5rem + 160px) 1.5rem 0 1.5rem;
    margin: 0 auto;
    --section-width: 40.5rem;

    @include respond-below('phone') {
      padding: 3rem;
    }
  }
  .description {
    padding-inline: 1.5rem;
    h1 {
      font-weight: normal;
    }
  }

  @include respond-below('phone') {
    .kpb-images-text-section__content {
      padding: 1.5rem !important;
    }
  }

  .residential-gallery {
    gap: 5px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .kpb-gallery__item {
      width: calc(100% / 3 - 5px + 5px / 3);

      flex-grow: 1;
      transition: transform 0.3s, -webkit-transform 0.3s;
      &:hover {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
        z-index: 10;
      }
    }
  }

  .kpb-images-text-section.kpb-section--width-medium {
    .kpb-images-text-section__images {
      .kpb-gallery__item {
        &-thumbnail {
          max-height: 50vh;
          object-fit: contain;
        }
      }
    }
  }

  .features {
    .kpb-section__container {
      max-width: 60rem;
    }

    .kpb-features__content {
      h3 {
        strong {
          font-weight: normal;
        }

        margin: 0 0 0.5rem;
      }
    }

    --section-padding-inline: 0;

    padding-block: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-below('phone') {
      padding: 1.25rem 1.75rem;
    }
  }
}
</style>
